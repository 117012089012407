import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Article from '../components/Article';

export default () => (
  <Layout>
    <SEO title="404: Not found" />
    <Article title="Seite nicht gefunden">
      <p>Die angeforderte Seite konnte nicht gefunden werden.</p>
    </Article>
  </Layout>
);
